@tailwind base;
@tailwind components;
@tailwind utilities;

/* @layer utilities {
  .pause {
    animation-play-state: paused;
  }
} */

@font-face {
  font-family: Moulin;
  src: url('/font/moulin/Moulin-Bold.ttf');
  font-weight: bold;
}


@font-face {
  font-family: 'Moulin Trial';
  src: url('/font/moulin/Moulin-Regular-Trial.otf');
}

@font-face {
  font-family: 'Moulin Trial';
  src: url('/font/moulin/Moulin-Bold-Trial.otf');
  font-weight: bold;
}

@font-face {
  font-family: 'Moulin Trial';
  src: url('/font/moulin/Moulin-Light-Trial.otf');
  font-weight: 300;
}

@font-face {
  font-family: 'Moulin Trial';
  src: url('/font/moulin/Moulin-Medium-Trial.otf');
  font-weight: 500;
}

@font-face {
  font-family: 'Moulin Trial';
  src: url('/font/moulin/Moulin-Semibold-Trial.otf');
  font-weight: 600;
}

@font-face {
  font-family: 'Moulin Trial';
  src: url('/font/moulin/Moulin-Black-Trial.otf');
  font-weight: 900;
}

@font-face {
  font-family: 'Moulin Trial';
  src: url('/font/moulin/Moulin-Italic-Trial.otf');
  font-style: italic;
}

@font-face {
  font-family: 'Moulin Trial';
  src: url('/font/moulin/Moulin-BoldItalic-Trial.otf');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Moulin Trial';
  src: url('/font/moulin/Moulin-LightItalic-Trial.otf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Moulin Trial';
  src: url('/font/moulin/Moulin-MediumItalic-Trial.otf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Moulin Trial';
  src: url('/font/moulin/Moulin-Thin-Trial.otf');
  font-weight: 100;
}

@font-face {
  font-family: 'Moulin Trial';
  src: url('/font/moulin/Moulin-ThinItalic-Trial.otf');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'SN Pro';
  src:
    url('/font/sn-pro/SNPro-Regular.otf'),
    url('/font/sn-pro/SNPro-Regular.woff'),
    url('/font/sn-pro/SNPro-Regular.woff2');
}

@font-face {
  font-family: 'SN Pro';
  src:
    url('/font/sn-pro/SNPro-Bold.otf'),
    url('/font/sn-pro/SNPro-Bold.woff'),
    url('/font/sn-pro/SNPro-Bold.woff2');
  font-weight: bold;
}

@font-face {
  font-family: 'SN Pro';
  src:
    url('/font/sn-pro/SNPro-Light.otf'),
    url('/font/sn-pro/SNPro-Light.woff'),
    url('/font/sn-pro/SNPro-Light.woff2');
  font-weight: 300;
}

@font-face {
  font-family: 'SN Pro';
  src:
    url('/font/sn-pro/SNPro-Medium.otf'),
    url('/font/sn-pro/SNPro-Medium.woff'),
    url('/font/sn-pro/SNPro-Medium.woff2');
  font-weight: 500;
}

@font-face {
  font-family: 'SN Pro';
  src:
    url('/font/sn-pro/SNPro-Semibold.otf'),
    url('/font/sn-pro/SNPro-Semibold.woff'),
    url('/font/sn-pro/SNPro-Semibold.woff2');
  font-weight: 600;
}

@font-face {
  font-family: 'SN Pro';
  src:
    url('/font/sn-pro/SNPro-Black.otf'),
    url('/font/sn-pro/SNPro-Black.woff'),
    url('/font/sn-pro/SNPro-Black.woff2');
  font-weight: 900;
}

@font-face {
  font-family: 'SN Pro';
  src:
    url('/font/sn-pro/SNPro-Italic.otf'),
    url('/font/sn-pro/SNPro-Italic.woff'),
    url('/font/sn-pro/SNPro-Italic.woff2');
  font-style: italic;
}

@font-face {
  font-family: 'SN Pro';
  src:
    url('/font/sn-pro/SNPro-BoldItalic.otf'),
    url('/font/sn-pro/SNPro-BoldItalic.woff'),
    url('/font/sn-pro/SNPro-BoldItalic.woff2');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'SN Pro';
  src:
    url('/font/sn-pro/SNPro-LightItalic.otf'),
    url('/font/sn-pro/SNPro-LightItalic.woff'),
    url('/font/sn-pro/SNPro-LightItalic.woff2');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'SN Pro';
  src:
    url('/font/sn-pro/SNPro-MediumItalic.otf'),
    url('/font/sn-pro/SNPro-MediumItalic.woff'),
    url('/font/sn-pro/SNPro-MediumItalic.woff2');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  /* thin */
  font-family: 'SN Pro';
  src:
    url('/font/sn-pro/SNPro-Thin.otf'),
    url('/font/sn-pro/SNPro-Thin.woff'),
    url('/font/sn-pro/SNPro-Thin.woff2');
  font-weight: 100;
}

@layer base {
  :root {
    --shadcn-background: 0 0% 100%;
    --shadcn-foreground: 0 0% 3.9%;
    --shadcn-card: 0 0% 100%;
    --shadcn-card-foreground: 0 0% 3.9%;
    --shadcn-popover: 0 0% 100%;
    --shadcn-popover-foreground: 0 0% 3.9%;
    --shadcn-primary: 0 0% 9%;
    --shadcn-primary-foreground: 0 0% 98%;
    --shadcn-secondary: 0 0% 96.1%;
    --shadcn-secondary-foreground: 0 0% 9%;
    --shadcn-muted: 0 0% 96.1%;
    --shadcn-accent: 0 0% 96.1%;
    --shadcn-accent-foreground: 0 0% 9%;
    --shadcn-destructive: 0 84.2% 60.2%;
    --shadcn-destructive-foreground: 0 0% 98%;
    --shadcn-blue: 221 83% 53%;
    --shadcn-blue-foreground: 0 0% 98%;
    --shadcn-green: 142 71% 45%;
    --shadcn-green-foreground: 0 0% 98%;
    --shadcn-purple: 263 83% 58%;
    --shadcn-purple-foreground: 0 0% 98%;
    --shadcn-pink: 335 83% 60%;
    --shadcn-pink-foreground: 0 0% 98%;
    --shadcn-border: 0 0% 89.8%;
    --shadcn-input: 0 0% 89.8%;
    --shadcn-ring: 0 0% 3.9%;
    --shadcn-radius: 0.5rem;
    --shadcn-radius-small: .25rem;
    --shadcn-radius-large: 1rem;

    /* new colors */
    --gray-50: #F0F0F5;
    --gray-25: #F0F0F580;
    --gray-100: #EBEBEF;
    --gray-150: #EAEAF0;
    --gray-200: #D1D1DB;
    --gray-300: #A9A9BC;
    --gray-400: #8A8AA3;
    --gray-500: #6C6C89;
    --gray-600: #55556D;
    --gray-700: #3F3F50;
    --gray-800: #282833;
    --gray-900: #121217;
    --gray-450: #12121780; /* 50% */
    --white-50: rgb(255 255 255 / 500%);
    --white-60: rgb(255 255 255 / 600%);
    --white-100: rgb(255 255 255 / 1000%);
    --white-200: rgb(255 255 255 / 2000%);
    --white-300: rgb(255 255 255 / 3000%);
    --white-400: rgb(255 255 255 / 4000%);
    --white-500: rgb(255 255 255 / 5000%);
    --white-600: rgb(255 255 255 / 6000%);
    --white-700: rgb(255 255 255 / 8000%);
    --white-800: rgb(255 255 255 / 9000%);
    --white-900: #fff;
    --band-50: #F4F1FD;
    --band-100: #E5DFFF;
    --band-150: #D7D0FF;
    --band-200: #cac0ff;
    --band-300: #b0a0ff;
    --band-400: #9581ff;
    --band-500: #7b61ff;
    --band-600: #624ecc;
    --band-700: #4a3a99;
    --band-800: #312766;
    --band-900: #191333;
    --band-disabled: rgb(149 129 255 / 50%);
    --red-50: #fef0f4;
    --red-100: #fdd8e1;
    --red-200: #fbb1c4;
    --red-300: #f98ba6;
    --red-400: #f76489;
    --red-500: #f53d6b;
    --red-600: #f3164e;
    --red-700: #d50b3e;
    --red-800: #af0932;
    --red-900: #880727;
    --yellow-50: #fff9eb;
    --yellow-100: #fff3d6;
    --yellow-200: #ffe7ad;
    --yellow-300: #ffda85;
    --yellow-400: #ffce5c;
    --yellow-500: #ffc233;
    --yellow-600: #faaf00;
    --yellow-700: #c28800;
    --yellow-800: #8a6100;
    --yellow-900: #523900;
    --green-50: #eefbf4;
    --green-100: #dff8ea;
    --green-200: #b2eecc;
    --green-300: #84e4ae;
    --green-400: #56d990;
    --green-500: #2dca72;
    --green-600: #26a95f;
    --green-700: #1e874c;
    --green-800: #17663a;
    --green-900: #0f4527;
    --orange-50: #fff2ee;
    --orange-100: #ffe8e1;
    --orange-200: #ffcdbd;
    --orange-300: #ffb399;
    --orange-400: #ff9876;
    --orange-500: #ff7d52;
    --orange-600: #ff571f;
    --orange-700: #eb3a00;
    --orange-800: #b82e00;
    --orange-900: #852100;
    --pink-50: #feecfb;
    --pink-100: #fdddf8;
    --pink-200: #fcc5f3;
    --pink-300: #fa99ea;
    --pink-400: #f87ce4;
    --pink-500: #f75fde;
    --pink-600: #f42ad3;
    --pink-700: #db0bb9;
    --pink-800: #a5088c;
    --pink-900: #70065f;
    --blue-50: #f0faff;
    --blue-100: #dbf3ff;
    --blue-200: #ade4ff;
    --blue-300: #70d1ff;
    --blue-400: #38beff;
    --blue-500: #00acff;
    --blue-600: #0090d6;
    --blue-700: #0075ad;
    --blue-800: #005985;
    --blue-900: #003e5c;
    --icon-info: var(--blue-600);
    --icon-caution: var(--yellow-700);
    --icon-critical-hover: var(--red-800);
    --icon-critical: var(--red-600);
    --icon-success: var(--green-600);
    --icon-disable: var(--gray-200);
    --icon-on-bright-color: var(--gray-800);
    --icon-on-color: var(--white-900);
    --icon-interactive-hover: var(--band-700);
    --icon-interactive: var(--band-500);
    --icon: var(--gray-500);
    --icon-subdued: var(--gray-400);
    --text-interactive-hover: var(--band-700);
    --text-interactive: var(--band-500);
    --text-info: var(--blue-600);
    --text-caution: var(--yellow-700);
    --text-text-critical-hover: var(--red-800);
    --text-text-critical: var(--red-600);
    --text-success: var(--green-600);
    --text-on-color: var(--white-900);
    --text-disabled: var(--gray-200);
    --text: var(--gray-800);
    --text-subdued: var(--gray-400);
    --surface-info: var(--blue-100);
    --surface-caution: var(--yellow-100);
    --surface-critical-strong-hover: var(--red-700);
    --surface-critical-strong: var(--red-500);
    --surface-critical: var(--red-100);
    --surface-success: var(--green-100);
    --surface-base: var(--gray-50);
    --surface-primary-disable: var(--band-disabled);
    --surface-primary-hover: var(--band-600);
    --surface-primary: var(--band-500);
    --surface-active: var(--band-100);
    --surface-active-hover: var(--band-150);
    --surface-hover: var(--gray-50);
    --surface-on-video: var(--gray-900);
    --surface-subdued: var(--gray-50);
    --surface-semisubdued: var(--gray-25);
    --surface: var(--white-900);
    --border-info: var(--blue-300);
    --border-caution: var(--yellow-400);
    --border-border-critical-hover: var(--red-500);
    --border-border-critical: var(--red-300);
    --border-success: var(--green-300);
    --border-disable: var(--gray-50);
    --border-active: var(--band-500);
    --border-hover: var(--gray-200);
    --border-button: var(--gray-150);
    --border: var(--gray-100);
    --color-background-avatar-placeholder: #ebebef;
  }

  .dark, [data-theme='dark'] {
    --shadcn-background: 0 0% 4%;
    --shadcn-foreground: 0 0% 98%;
    --shadcn-card: 0 0% 4%;
    --shadcn-card-foreground: 0 0% 98%;
    --shadcn-popover: 0 0% 4%;
    --shadcn-popover-foreground: 0 0% 98%;
    --shadcn-primary: 61 100% 68%;
    --shadcn-primary-foreground: 0 0% 4%;
    --shadcn-secondary: 0 0% 16%;
    --shadcn-secondary-foreground: 61 100% 68%;
    --shadcn-muted: 0 0% 14%;
    --shadcn-accent: 0 0% 14%;
    --shadcn-accent-foreground: 0 0% 98%;
    --shadcn-destructive: 0 72% 51%;
    --shadcn-destructive-foreground: 0 0% 98%;
    --shadcn-blue: 221 83% 53%;
    --shadcn-blue-foreground: 0 0% 98%;
    --shadcn-green: 142 71% 45%;
    --shadcn-green-foreground: 0 0% 98%;
    --shadcn-purple: 263 83% 58%;
    --shadcn-purple-foreground: 0 0% 98%;
    --shadcn-pink: 335 83% 60%;
    --shadcn-pink-foreground: 0 0% 98%;
    --shadcn-border: 0 0% 15%;
    --shadcn-input: 0 0% 15%;
    --shadcn-ring: 0 0% 83%;
    --shadcn-ring-dark: 0 0% 40%;

    /* new style */
    --icon-info: var(--blue-300);
    --icon-caution: var(--yellow-400);
    --icon-critical-hover: var(--red-600);
    --icon-critical: var(--red-300);
    --icon-success: var(--green-300);
    --icon-disable: var(--gray-600);
    --icon-on-bright-color: var(--gray-800);
    --icon-on-color: var(--white-900);
    --icon-interactive-hover: var(--band-200);
    --icon-interactive: var(--band-400);
    --icon: var(--gray-300);
    --icon-subdued: var(--gray-500);
    --text-interactive-hover: var(--band-200);
    --text-interactive: var(--band-400);
    --text-info: var(--blue-300);
    --text-caution: var(--yellow-400);
    --text-text-critical-hover: var(--red-600);
    --text-text-critical: var(--red-300);
    --text-success: var(--green-300);
    --text-on-color: var(--white-900);
    --text-disabled: var(--gray-600);
    --text: var(--gray-100);
    --text-subdued: var(--gray-400);
    --surface-info: var(--blue-900);
    --surface-caution: var(--yellow-900);
    --surface-critical-strong-hover: var(--red-200);
    --surface-critical-strong: var(--red-400);
    --surface-critical: var(--red-900);
    --surface-success: var(--green-900);
    --surface-base: var(--gray-900);
    --surface-primary-disable: var(--band-disabled);
    --surface-primary-hover: var(--band-300);
    --surface-primary: var(--band-400);
    --surface-active: var(--band-800);
    --surface-active-hover: var(--band-700);
    --surface-hover: var(--gray-700);
    --surface-on-video: var(--gray-900);
    --surface-subdued: var(--gray-900);
    --surface-semisubdued: var(--gray-450);
    --surface: var(--gray-800);
    --border-info: var(--blue-800);
    --border-caution: var(--yellow-800);
    --border-border-critical-hover: var(--red-600);
    --border-border-critical: var(--red-800);
    --border-success: var(--green-800);
    --border-disable: var(--gray-800);
    --border-active: var(--band-300);
    --border-hover: var(--gray-500);
    --border-button: var(--gray-700);
    --border: var(--gray-700);
    --color-background-avatar-placeholder: var(--surface-subdued)
  }
}

/* typography */

@layer base {
  * {
    @apply border-border;
  }

  body {
    height: 100vh;
    height: 100dvh;

    @apply bg-surface text-text w-screen md:h-screen font-sn;

    min-width: 100vw;
  }
}

@layer utilities {
  .fill-current-color path,
  .fill-current-color circle,
  .fill-current-color polygon {
    fill: currentcolor;
  }

  .stroke-current-color path,
  .stroke-current-color circle,
  .stroke-current-color polygon {
    stroke: currentcolor;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  /* hide input spin box on Chrome and Safari */
  input.hide-spin::-webkit-outer-spin-button,
  input.hide-spin::-webkit-inner-spin-button {
    appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  /* hide input spin box on Firefox */
  input.hide-spin[type="number"] {
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -moz-appearance:textfield; /* Firefox */
  }

  /* https://gist.github.com/th3hamburgler/c9bec81954ec0b99ad7a8658a6c352fb */
  .p-safe {
    padding: env(safe-area-inset-top) env(safe-area-inset-right)
      env(safe-area-inset-bottom) env(safe-area-inset-left);
  }

  .px-safe {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }

  .py-safe {
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
  }

  .pt-safe {
    padding-top: env(safe-area-inset-top);
  }

  .pr-safe {
    padding-right: env(safe-area-inset-right);
  }

  .pb-safe {
    padding-bottom: env(safe-area-inset-bottom);
  }

  .pl-safe {
    padding-left: env(safe-area-inset-right);
  }

  .m-safe {
    margin: env(safe-area-inset-top) env(safe-area-inset-right)
      env(safe-area-inset-bottom) env(safe-area-inset-left);
  }

  .mx-safe {
    margin-left: env(safe-area-inset-left);
    margin-right: env(safe-area-inset-right);
  }

  .my-safe {
    margin-top: env(safe-area-inset-top);
    margin-bottom: env(safe-area-inset-bottom);
  }

  .mt-safe {
    margin-top: env(safe-area-inset-top);
  }

  .mr-safe {
    margin-right: env(safe-area-inset-right);
  }

  .mb-safe {
    margin-bottom: env(safe-area-inset-bottom);
  }

  .ml-safe {
    margin-left: env(safe-area-inset-right);
  }

  .top-safe {
    top: 0;
    top: env(safe-area-inset-top);
  }

  .right-safe {
    right: 0;
    right: env(safe-area-inset-right);
  }

  .bottom-safe {
    bottom: 0;
    bottom: env(safe-area-inset-bottom);
  }

  .left-safe {
    left: 0;
    left: env(safe-area-inset-right);
  }
}

html, body {
  overscroll-behavior-x: none;
}

* {
  /* @apply transition-all; */

  @apply transition duration-75;
}

[data-radix-popper-content-wrapper] {
  /* @apply transition-all; */

  /* @apply transition-none border border-border rounded-lg; */
  @apply transition-none;
}

.infinite-scroll-component {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.infinite-scroll-component::-webkit-scrollbar {
  display: none;
}

.infinite-scroll-component__outerdiv {
  /* flex: 1;
  height: 0;
  width: 100%;
  display: flex; */
}

.infinite-scroll-component__outerdiv .infinite-scroll-component {
  width: 100%;
}

video::-webkit-media-controls {
  display:none !important;
}

.bg-dots {
  background-image: url('https://scontent.haiper.ai/webapp/images/dot-100.png');
  background-repeat: repeat;
  background-position: 0 0;
}

[data-theme='dark'] .bg-dots {
  background-image: url('https://scontent.haiper.ai/webapp/images/dot-30.png') !important;
}

button:focus {
  outline: none;
}

button:focus-visible {
  outline: none;
}

button:focus:focus-visible {
  outline: none;
}
